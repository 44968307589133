import { useState } from 'react';
import React, { useEffect } from 'react';
import { TileLayer } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  setDisplay,
  setDisplay2,
} from '@/Store/Actions/infoDisplayAction';
import AgrosLogo from '@/Assets/agrosLogo.png';
import { MdTerrain, FaSatelliteDish, FaMap } from '@/Styles/Icons';

import BackButton from '../BackButtonMap';

import {
  Container,
  Logo,
  GlobalStyle,
  MapTypeContainer,
} from './styles';

function Map({ children, props }) {
  const [mapType, setMapType] = useState('terrain');

  const { zoom = null, center = [] } = props || {};

  const token = localStorage.getItem('token');
  const farmName = localStorage.getItem('farmName');

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) history.push('/Login');
  }, [history, token]);

  useEffect(() => {
    dispatch(setDisplay2(false));
    dispatch(setDisplay(false));
    //eslint-disable-next-line
  }, []);

  const test = [-23.4435, -48.5778];
  const isTest = farmName === 'Pecuária';

  const coords = center.length > 0 ? center : [-23, -47];
  const parsedCoords = isTest ? test : coords;

  function isSelected(type) {
    if (type === mapType) {
      return { background: 'rgba(3, 43, 11, 1)' };
    }

    return null;
  }

  function getColor(type) {
    if (type === mapType) {
      return '#fff';
    }

    return 'rgba(3, 43, 11, 0.82)';
  }

  return (
    <Container zoom={zoom || 13} center={parsedCoords}>
      <GlobalStyle />

      {mapType === 'terrain' && (
        <TileLayer
          maxZoom={22}
          // url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={['a', 'b', 'c']}
        />
      )}

      {mapType === 'hybrid' && (
        <TileLayer
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
        />
      )}

      {mapType === 'satellite' && (
        <TileLayer
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        />
      )}

      {farmName === 'Concorde' && (
        <TileLayer
          minZoom={19}
          maxZoom={22}
          url="https://drone-images-map.s3.amazonaws.com/concorde/220-221-222-228-229-230/{z}/{x}/{y}.png"
        />
      )}

      {farmName === 'AG-Citrus' && (
        <TileLayer
          minZoom={18}
          maxZoom={20}
          url="https://drone-images-map.s3.amazonaws.com/agcitrus/{z}/{x}/{y}.png"
        />
      )}

      {farmName === 'Frutas Scholl' && (
        <TileLayer
          minZoom={18}
          maxZoom={20}
          url="https://drone-images-map.s3.amazonaws.com/scholl/{z}/{x}/{y}.png"
        />
      )}

      {/* {(farmName === 'Pecuária' || farmName === 'Concorde') && (
        <>
          {tiles2.map(({ key }) => (
            <TileLayer
              key={key}
              minZoom={19}
              maxZoom={21}
              url={`https://api.maptiler.com/tiles/${key}/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAP_TILER_KEY_2}`}
            />
          ))}

          {tiles.map(({ key }) => (
            <>
              <TileLayer
                key={key}
                minZoom={19}
                maxZoom={21}
                url={`https://api.maptiler.com/tiles/${key}/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAP_TILER_KEY}`}
              />
            </>
          ))}
        </>
      )} */}

      <Logo src={AgrosLogo} />

      {children}

      <MapTypeContainer>
        <div
          onClick={() => setMapType('hybrid')}
          style={isSelected('hybrid')}
        >
          <FaMap size={15} color={getColor('hybrid')} />
        </div>

        <div
          onClick={() => setMapType('satellite')}
          style={isSelected('satellite')}
        >
          <FaSatelliteDish size={15} color={getColor('satellite')} />
        </div>

        <div
          onClick={() => setMapType('terrain')}
          style={isSelected('terrain')}
        >
          <MdTerrain size={20} color={getColor('terrain')} />
        </div>
      </MapTypeContainer>

      <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
    </Container>
  );
}

export default Map;
